<template>
  <div class="admin-guidance-help-videos">
    <!-- Page Header -->
    <div class="page-header">
      <h1 class="page-title">
        <!-- <a-button type="default" style="display: inline; margin-right: 15px"
          @click.prevent="$router.push('/admin/guidance')" icon="left"></a-button> -->

        <admin-org-indicator></admin-org-indicator>

        Admin - Guidance - Help Videos
      </h1>
      <div class="actions">
        <a-button @click.prevent="loadHelpVideos" :disabled="isLoading" class="button-margin-left btn-rounded"
          icon="reload" size="large" type="default">Refresh</a-button>

        <a-button v-if="showCreateButton" @click.prevent="createHelpVideo" class="button-margin-left btn-rounded"
          icon="plus" size="large" type="primary">Create</a-button>
      </div>
    </div>
    <!-- / Page Header -->

    <!-- List wrapper -->
    <div class="topics-list-wrapper">


      <div class="admin-guidance-context-selector-wrapper">
        <admin-guidance-context-selector v-if="!isLoading"></admin-guidance-context-selector>
      </div>

      <a-alert
        style="margin-bottom: 25px"
        message="A visual instructional video designed to teach users how to perform a specific task or learn a particular skill."
      >
      </a-alert>

            <!-- Loading -->
            <div class="loader" v-if="isLoading">
        <a-spin></a-spin>
      </div>
      <!-- / Loading -->


      <!-- No help videos -->
      <a-alert v-if="!isLoading && helpVideosToShow.length == 0" type="info" message="No help videos to show">
      </a-alert>
      <!-- / No help videos -->

      <!-- Help videos list -->
      <div class="help-videos-list" v-if="!isLoading && helpVideosToShow.length" :key="ownerIdFilter">
        <a-row type="flex" :gutter="20">
          <a-col :span="colSpan" v-for="helpVideo in helpVideosToShow" :key="helpVideo.id">
            <help-video-list-item :help-video="helpVideo" :tenant-id="tenantId" :org-id="selectedOrganisation.id"
              @selected="() => navigateToHelpVideo(helpVideo)"></help-video-list-item>
          </a-col>
        </a-row>
      </div>
      <!-- / Help videos list -->
    </div>
    <!-- / List wrapper -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import HelpVideoListItem from "./HelpVideos/HelpVideoListItem.vue";
import AdminGuidanceContextSelector from "../../../components/Admin/Guidance/AdminGuidanceContextSelector.vue";
export default {
  components: { HelpVideoListItem, AdminGuidanceContextSelector },

  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },

  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },

  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    }
    this.loadHelpVideos();
  },

  watch: {
    selectedOrganisation() {
      this.loadTopics();
    },
  },

  methods: {
    ...mapActions("adminHelpVideos", {
      loadHelpVideos: "loadHelpVideos",
    }),

    navigateToHelpVideo(helpVideo) {
      this.$router.push("/admin/guidance/help-videos/" + helpVideo["$v"].id);
    },

    createHelpVideo() {
      this.$router.push("/admin/guidance/help-videos/create");
    },
  },

  computed: {
    ...mapGetters("admin", {
      selectedOrganisation: "selectedOrganisation",
      tenantId: "tenantId",
    }),

    ...mapGetters("adminHelpVideos", {
      isLoading: "isLoading",
      helpVideosToShow: "helpVideosToShow",
    }),

    ...mapGetters("adminGuidance", {
      ownerIdFilter: "ownerIdFilter",
    }),

    colSpan() {
      return this.windowWidth < 1200 ? 24 : 12;
    },

    showCreateButton() {
      return (!this.ownerIdFilter || this.ownerIdFilter == this.selectedOrganisation.id)
    }
  },
};
</script>

<style scoped lang="scss">
.help-video-list-item {
  margin-bottom: 15px;
}

.button-margin-left {
  margin-left: 15px;
}

.admin-guidance-context-selector-wrapper {
  margin-bottom: 30px;
}
</style>